import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';
import { hospital } from './hospital.module';

Vue.use(Vuex)
const vuexLocalStorage = new VuexPersist({
    key: 'vuex', // The key to store the state on in the storage provider.
    storage: window.localStorage, // or window.sessionStorage or localForage
    // Function that passes the state and returns the state with only the objects you want to store.
    reducer: state => {
        return state;
    },    
})
export default new Vuex.Store({
    plugins: [vuexLocalStorage.plugin],
    modules: {
        hospital,
    }
})