import Vue from 'vue'
import VueRouter from 'vue-router'
import Paths from './router_path/index'
import Base from '../App.vue'
Vue.use(VueRouter)
const routes = [
  ...Paths,
]

const router = new VueRouter({
    mode: 'history',
    routes: [{
      base: '',
      component: Base,
      path: '/',
      children: routes
    },{
      path: '/',
      redirect: '/',
    }],
    scrollBehavior(to, from, savedPosition) { return { x: 0, y: 0 } }
});

router.beforeEach((to, from, next) => {
    next();
});
export default router