import hospitalApi from '../apis/hospital';

const state = {};
const actions = {    
    registerAsClientAPI ({ commit }, data) {
        return hospitalApi.registerAsClient(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    resendOTPAPI({ commit }, data) {
        return hospitalApi.resendOTP(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    verifyOTPAPI({ commit }, data) {
        return hospitalApi.verifyOTP(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateDetailsAPI({ commit }, data) {
        return hospitalApi.updateDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getSubscriptionPlansAPI({ commit }, data) {
        return hospitalApi.getSubscriptionPlans(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    sendInquiryAPI({ commit }, data) {
        return hospitalApi.sendInquiry(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
};
const mutations = {};
const getters = {};
export const hospital = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};