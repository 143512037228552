import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import '@/assets/css/animate.css';
import '@/assets/css/bootstrap.min.css';
import '@/assets/css/lineicons.css';
import '@/assets/css/ud-styles.css';
import '@/assets/js/bootstrap.bundle.min.js';
import '@/assets/js/wow.min.js';
import Vuelidate from "vuelidate";
import store from './store'
import axios from 'axios';
import config from './config';
import router from './router'

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
window.axios = axios;
Vue.prototype.$store = store

window.axios.defaults.baseURL = config.api_url;
window.axios.defaults.headers.common['Content-Type'] = 'application/json';

new Vue({
    router,
    store,
  render: h => h(App),
}).$mount('#app')