const Home = () => import('../../views/home.vue')
const Signup = () => import('../../views/signup.vue')
const PrivacyPolicy = () => import('../../views/pages/privacy_policy.vue')
const TermsAndConditions = () => import('../../views/pages/terms_and_conditions.vue')
const RefundPolicy = () => import('../../views/pages/refund_policy.vue')
const TermsAndConditionsCustomizations = () => import('../../views/pages/terms_and_conditions_customizations.vue')

const router_path = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: TermsAndConditions
    },
    {
        path: '/refund-policy',
        name: 'refund-policy',
        component: RefundPolicy
    },
    {
        path: '/tnc-customization',
        name: 'tnc-customization',
        component: TermsAndConditionsCustomizations
    },
]

export default router_path;
